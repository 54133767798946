<template>
  <div>
    <v-dialog v-model="acceDialog" persistent max-width="400">
      <v-card :loading="loading" loader-height="1">
        <v-toolbar flat>
          <v-toolbar-title> Patient Acceptance </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle style="padding-top: 1px">
          Accept {{ patient | capitalize }} below if you agree to offer your
          service to this client.
        </v-card-subtitle>
        <v-card-text>
          <div class="mylist">
            <ul>
              <li>
                <div class="myleft">Patient</div>
                <div class="myright">{{ patient | capitalize }}</div>
                <div class="clearall"></div>
              </li>
              <li>
                <div class="myleft">date</div>
                <div class="myright">{{ dating | myDate }}</div>
                <div class="clearall"></div>
              </li>
              <li>
                <div class="myleft">time</div>
                <div class="myright">
                  {{ starts | myTime }} to {{ ends | myTime }}
                </div>
                <div class="clearall"></div>
              </li>
            </ul>
          </div>
          <v-card outlined style="">
            <v-card-text>
              <v-switch
                v-model="details.accepted"
                :label="`Acceptance: ${details.accepted}`"
              ></v-switch>
              <v-switch
                v-model="details.moreInfo"
                :label="`require more Info: ${details.moreInfo}`"
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="red" v-on="on" @click="closeDialog">
                NO
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="blue" v-on="on" @click="submit">
                yes
              </v-btn>
            </template>
            <span>Submit</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
export default {
  props: {
    acceDialog: Boolean,
    myId: { type: Number, default: 0 },
    patientId: { type: Number, default: 0 },
    patient: { type: String, default: null },
    dating: { type: String, default: null },
    starts: { type: String, default: null },
    ends: { type: String, default: null },
  },
  data: () => ({
    details: {
      accepted: null,
      moreInfo: true,
    },
    loading: false,
  }),
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.medics.patient
        .appointDetails(this.myId)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.details.accepted = response.data.accepted;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeAccept", payload);
    },
    submit() {
      let acceptance = this.details.accepted;
      let moreInfo = this.details.moreInfo;
      let patientRequestId = this.patientId;
      let data = { patientRequestId, acceptance, moreInfo };
      //   console.log(data);
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitAccept", payload);
    },
  },
};
</script>
