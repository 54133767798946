<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card outlined>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="appointmentlist"
            :items-per-page="10"
            :loading="loading"
            :sort-by="['id']"
            :sort-desc="[true]"
            loader-height="1"
          >
            <template v-slot:item.patient="{ item }">
              {{ item.patient | capitalize }}
            </template>

            <template v-slot:item.paid="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :color="item.paid ? 'primary' : 'error'"
                    :disabled="cashPayOnly(item.pay_type, item.accepted)"
                    icon
                    v-on="on"
                    @click.stop="$set(cashDialog, item.id, true)"
                  >
                    <v-icon>
                      {{ item.paid ? "mdi-check" : "mdi-close" }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Confirm payment with Cash</span>
              </v-tooltip>
              <CashPay
                v-if="cashDialog[item.id]"
                :cashDialog="cashDialog[item.id]"
                :myId="item.id"
                @closePay="closePay"
                @submitPay="submitPay"
              />
            </template>

            <template v-slot:item.schedule_date="{ item }">
              {{ item.schedule_date | myDate }}
            </template>
            <template v-slot:item.date_start="{ item }">
              {{ item.date_start | simpleDating }}
            </template>

            <template v-slot:item.accepted="{ item }">
              <v-btn
                :color="item.accepted ? 'primary' : 'error'"
                dark
                elevation="0"
                fab
                x-small
                @click.stop="$set(acceDialog, item.id, true)"
              >
                <v-icon>{{ item.accepted ? "mdi-check" : "mdi-close" }}</v-icon>
              </v-btn>
              <ProviderAccept
                v-if="acceDialog[item.id]"
                :acceDialog="acceDialog[item.id]"
                :dating="item.date_start"
                :ends="item.time_end"
                :myId="item.id"
                :patient="item.patient"
                :patientId="item.patient_requestid"
                :starts="item.time_start"
                @closeAccept="closeAccept"
                @submitAccept="submitAccept"
              />
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                color="primary"
                text
                x-small
                @click.stop="$set(detailDialog, item.id, true)"
              >
                detail
              </v-btn>
              <AppointmentDetail
                v-if="detailDialog[item.id]"
                :detailDialog="detailDialog[item.id]"
                :myId="item.id"
                @closeDetail="closeDetail"
              />

              <v-btn
                :to="{
                  name: 'medics.appointment.more',
                  params: {
                    id: _encode(item.id),
                    date: _encode(item.date_start),
                    start: _encode(item.time_start),
                    loc: _encode(item.meeting_location),
                    end: _encode(item.time_end),
                    pat: _encode(item.patient),
                  },
                }"
                color="success"
                text
                x-small
              >
                more info
              </v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :disabled="item.paid ? false : true"
                    :to="{
                      name: 'medics.treatment',
                      params: {
                        id: _encode(item.id),
                        name: _encode(item.patient),
                        patid: _encode(item.patient_requestid),
                      },
                    }"
                    color="success"
                    dark
                    x-small
                    v-on="on"
                  >
                    start
                  </v-btn>
                </template>
                <span>Start Treatment on {{ item.patient | capitalize }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../mixins/utils";
import WinScroll from "../../mixins/windowscroll";
import AppointmentDetail from "../../components/appointments/provider/detail.vue";
import ProviderAccept from "../../components/appointments/provider/acceptance.vue";
import CashPay from "../../components/cart/cashPayment.vue";

export default {
  components: { AppointmentDetail, ProviderAccept, CashPay },
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,

      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Appointments",
          to: { name: "medics.appointment" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      mySessionStatus: false,
      detailDialog: {},
      acceDialog: {},
      appointmentlist: [],
      cashDialog: {},
      loading: false,
      headers: [
        { text: "patient", value: "patient" },
        { text: "date", value: "schedule_date" },
        { text: "meeting type", value: "meeting_typesid" },
        { text: "paid", value: "paid", align: "center" },
        { text: "meeting location", value: "meeting_location" },
        { text: "accept/reject", value: "accepted", align: "center" },
        { text: "actions", value: "id", align: "center" },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    checkiSDone() {
      console.log("id", this._decode(this.id));
      Restful.subscribers.available
        .timeDetail(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          this.mySessionStatus = response.data.is_done;
          console.log("isDone", this.medTimeisDone);
          // if (response.data.id > 0) {
          //   this.myDiagnotics = true;
          // } else {
          //   this.myDiagnotics = false;
          // }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    cashPayOnly(a, acc) {
      // console.log('a', a)
      if (acc === true) {
        if (a === "mobile_money" || a === "cc") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isStart(v) {
      if (v === true) {
        return false;
      } else {
        return true;
      }
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.medics.patient
        .appointmentProvider()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.appointmentlist = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    closeDetail(p) {
      this.$set(this.detailDialog, p.id, p.state);
    },
    closeAccept(p) {
      this.$set(this.acceDialog, p.id, p.state);
    },
    submitAccept(p) {
      console.log(p);
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patient
        .acceptance(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.acceDialog, p.id, p.state);
    },
    menulink() {
      this.$emit("sidebar");
    },
    closePay(p) {
      this.$set(this.cashDialog, p.id, p.state);
    },
    submitPay(p) {
      console.log("submitPay", p);
      this.snack.bar = true;

      console.log("data", p.data);
      let self = this;
      Restful.medics.patient
        .paid(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = "error.response.data";
        });
      this.$set(this.cashDialog, p.id, p.state);
    },
  },
};
</script>
